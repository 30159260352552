import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";
// import CSPartnership from "components/CSPartnership/CSPartnership";
import { emailIsValid } from "utils/forms";
import { useRouter } from "next/router";
import SpinLoader from "components/SpinLoader/SpinLoader";
import ErrorBar from "components/ErrorBar/ErrorBar";
import Link from "next/link";
import SocialSignOn from "components/SocialSignOn/SocialSignOn";
import Layout from "components/Layout/Layout";
import CopyURL from "components/CopyURL/CopyURL";
import Container from "components/Container/Container";
import Input from "components/Form/Input";
import Button from "components/Form/Button";
import H1 from "components/TextElements/H1";
import { GetServerSidePropsContext } from "next";
import nookies from "nookies";
import admin from "firebase/admin";
import client from "firebase/client";
import axios from "axios";
import routes from "constants/routes";

const formErrorObj = {
  email: "",
  password: "",
  submit: "",
};

const Login = ({ auth }: { auth: boolean }) => {
  const router = useRouter();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [formErrors, setFormErrors] = useState(formErrorObj);
  const [isLoading, setIsLoading] = useState(false);

  // Update formData state
  const handleFormChange = (e: ChangeEvent<HTMLInputElement>) => {
    // Clear for errors on form change
    setFormErrors(formErrorObj);

    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleEmailValidation: () => boolean = () => {
    if (!emailIsValid(formData.email)) {
      setFormErrors({
        ...formErrors,
        email: "Please provide a valid email address",
      });
      return false;
    }
    return true;
  };

  const handlePasswordValidation: () => boolean = () => {
    if (!formData.password || formData.password.length < 8) {
      setFormErrors({
        ...formErrors,
        password: "Please provide a password to login",
      });
      return false;
    }
    return true;
  };

  // Login user with email + password
  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    // Clear form errors before validation
    setFormErrors(formErrorObj);
    setIsLoading(true);

    try {
      if (!handleEmailValidation()) {
        return;
      }
      if (!handlePasswordValidation()) {
        return;
      }
      const credential = await client
        .auth()
        .signInWithEmailAndPassword(formData.email, formData.password);
      const token = await credential.user?.getIdToken();
      await axios.post(routes.API.LOGIN, { token });
      return router.push(routes.START);
    } catch (e) {
      setIsLoading(false);
      if (e.code === "auth/invalid-email") {
        return setFormErrors({
          ...formErrors,
          submit: "Please provide a valid email address",
        });
      } else if (e.code === "auth/user-disabled") {
        return setFormErrors({
          ...formErrors,
          submit:
            "Unable to log in. Please contact support for more information",
        });
      } else if (e.code === "auth/user-not-found") {
        return setFormErrors({
          ...formErrors,
          submit: "Please sign up, could not find a user with that email",
        });
      } else if (e.code === "auth/wrong-password") {
        return setFormErrors({
          ...formErrors,
          submit: "Your email or password is incorrect",
        });
      } else {
        return setFormErrors({
          ...formErrors,
          submit: "Unable to process log in request",
        });
      }
    }
  };

  return (
    <Layout
      auth={auth}
      seo={{
        title: "Log In | NuCalm®",
        index: true,
        follow: true,
        path: "login",
        description:
          "Log In to NuCalm® Stress and Fatigue Assessment. Measure your stress and fatigue levels with voice to help optimize your sleep and stress management.",
      }}
    >
      <main>
        <Container width="sm">
          <H1 className="mb-4 md:mb-6">Log In</H1>
          <form>
            {formErrors.submit && (
              <ErrorBar
                error={formErrors.submit}
                style={{ marginBottom: "8px" }}
              />
            )}
            <div className="mb-4">
              <label htmlFor="email">
                <Input
                  id="email"
                  type="email"
                  name="email"
                  placeholder="Email"
                  value={formData.email}
                  onChange={handleFormChange}
                  onBlur={handleEmailValidation}
                />
              </label>
            </div>

            {formErrors.email && (
              <ErrorBar
                error={formErrors.email}
                style={{ marginBottom: "8px" }}
              />
            )}
            <div className="mb-2">
              <label htmlFor="password">
                <Input
                  id="password"
                  type="password"
                  name="password"
                  placeholder="Password"
                  value={formData.password}
                  onChange={handleFormChange}
                  onBlur={handlePasswordValidation}
                />
              </label>
            </div>

            {formErrors.password && (
              <ErrorBar
                error={formErrors.password}
                style={{ marginBottom: "8px" }}
              />
            )}
            <div className="flex flex-row justify-between pt-1 pb-2">
              <Link href={routes.FORGOT_PASSWORD} passHref>
                <a className="text-white font-bold text-base no-underline">
                  Forgot Password
                </a>
              </Link>
              <Link href={routes.SIGNUP} passHref>
                <a className="text-white font-bold text-base no-underline">
                  Sign Up
                </a>
              </Link>
            </div>
            <CopyURL />
            <div className="pt-2">
              <Button
                width="full"
                type="submit"
                onClick={handleSubmit}
                color="white"
                disabled={isLoading}
              >
                {isLoading ? <SpinLoader /> : <span>Log In</span>}
              </Button>
            </div>
          </form>
          <div>
            <SocialSignOn
              text="or log in with"
              onError={(msg: string) =>
                setFormErrors({ ...formErrors, submit: msg })
              }
            />
          </div>
          {/* <CSPartnership /> */}
        </Container>
      </main>
    </Layout>
  );
};

export const getServerSideProps = async (ctx: GetServerSidePropsContext) => {
  try {
    const cookies = nookies.get(ctx);
    const session = await admin.auth().verifySessionCookie(cookies.session);
    // If user is authenticated we route them to start
    if (session) {
      return {
        redirect: {
          destination: routes.START,
        },
      };
    }
    // If no token or error return with empty props obj
    return {
      props: {
        auth: false,
      },
    };
  } catch (err) {
    return {
      props: {
        auth: false,
      },
    };
  }
};

export default Login;
